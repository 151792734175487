import { legacy_createStore as createStore } from "redux";
// state
// آرایه ای از محصولات
const initialState = [];

export const shoppingCardReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD":
      // تابع افزودن به سبد
      // 1- اگر محصول درون سبد وجود نداشت انرا اضافه کرده و یک فیلد تعداد هم به آن اضافه میکنیم
      if (!state.find((item) => item.id === action.payload.id)) {
        return [
          ...state,
          {
            ...action.payload,
            count: 1,
          },
        ];
      } else {
        // 2- اگر محصول وجود داشت آنرا درون آرایه پیدا کرده و به مقدارش یک واحد اضافه میکنیم
        const tempArray = [...state];
        const index = tempArray.findIndex(
          (item) => item.id === action.payload.id
        );
        tempArray[index].count++;

        return tempArray;
      }

    case "SUB":
      // تابع کم کردن از سبد
      // محصول قطعا در سبد وجود دارد  و باید ببینیم تعداد آن چندتا است
      if (action.payload.count > 1) {
        // اگر تعداد بیشتر از یک بود کافیست یک واحد از تعدادش کم کنیم
        const tempArray = [...state];
        const index = tempArray.findIndex(
          (item) => item.id === action.payload.id
        );
        tempArray[index].count--;

        return tempArray;
      } else {
        // اگر فقط یک عدد مانده بود باید کل ردیفش را حذف کنیم چون تعداد صفر معنا ندارد
        const filteredArray = state.filter(
          (item) => item.id !== action.payload.id
        );

        return filteredArray;
      }

    case "REMOVE":
      // برای حذف یک محصول از سبد خرید
      const filteredArray = state.filter(
        (item) => item.id !== action.payload.id
      );

      return filteredArray;

    case "RESET":
      // حذف کامل سبد خرید
      return initialState;

    //پیش فرض
    default:
      return state;
  }
};

const store = createStore(shoppingCardReducer);

export default store;
