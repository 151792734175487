import React from "react";
import AboutDetails from "../components/about/AboutDetails";

const About = () => {
  return (

    <>
      <AboutDetails />
    </>
    
  );
};

export default About;
