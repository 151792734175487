import React from "react";
import ShoppingCardDetails from "../components/shopping-card/ShoppingCardDetails";

const ShoppingCard = () => {
  
  return (
    <div>
      <ShoppingCardDetails />
    </div>
  );
};

export default ShoppingCard;
