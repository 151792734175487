import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaShoppingBasket } from "react-icons/fa";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./Product.css";

import { useDispatch } from "react-redux";

const Product = ({ product }) => {
  const [count, setCount] = useState(0);
  
  const dispatch = useDispatch();

  const increase = () => {

    const objectTest = {
      type: "ADD",
      payload: product
    }

    dispatch(objectTest);

    toast.success("محصول به سبد خرید اضافه شد");


    // let currentCount = parseInt(count);
    // if (count < 10) {
    //   setCount(currentCount + 1);
    // } else {
    // }

  };

  // const decrease = () => {
  //   let currentCount = parseInt(count);
  //   if (currentCount > 0) {
  //     setCount(currentCount - 1);
  //   } else {
  //     toast.error("مقدار کمتر از صفر قابل انتخاب نیست!");
  //   }
  // };

  return (
    <>
      <div className="product-card">
        <Link to={`/product/${product.id}`}>
          <img className="product-card-image" src={product.image} />
        </Link>
        <div className="product-card-title">
          <h5 className="product-card-title-first">Name: {product.title}</h5>
          <h5>
            Price: <span className="card-price-span">{product.price}</span>
          </h5>
        </div>
        <div className="product-card-footer">
          <button onClick={increase} className="btn btn-primary">
            Add To Card <FaShoppingBasket />{" "}
          </button>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Product;
