import React from 'react';
import ConectDetails from "../components/conect/ConectDetails";

const Conect = () => {
    return (
        <>
           <ConectDetails />
        </>
    );
};

export default Conect;