import React from 'react';
import SailerDetails from '../components/sailer/SailerDetails';

const Sailers = () => {
    return (
        <>
            <SailerDetails />
        </>
    );
};

export default Sailers;