import React from 'react';

import "./Footer.css"

const Footer = () => {
    return (
        <div className='footer'>
           <h6>تمامی حقوق برای سایت نمونه کار محفوظ است</h6> 
        </div>
    );
};

export default Footer;