import React from 'react';
import "./ConectDetails.css"

const ConectDetails = () => {
    return (
        <div className='conect-container'>
            <h1>تماس با ما</h1>
            <h2>چگونه میتوانید با ما در ارتباط باشید</h2>
            <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel at
                consectetur aperiam, facere esse distinctio ut placeat natus aspernatur!
                Et incidunt distinctio labore architecto aut voluptatum sint dicta?
                Mollitia veniam aperiam ipsum natus, repellat doloribus dolores facer
                voluptatem dolorem id officiis, fugiat et laborum cumque optio nisi
                mollitia commodi adipisci consectetur similique beatae, aut
                perspiciatis. Impedit cum excepturi exercitationem delectus quasi.
            </p>

        </div>
    );
};

export default ConectDetails;