import React from 'react';
import "./SailerDetails.css";

const SailerDetails = () => {
    return (
        <div className='sailer-container'>
            <h1>فروشندگان</h1>
            <br></br>
            <h2>آنچه که درباره ما ، لازمه بدونید</h2>
            <br></br>
            <br></br>
            <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel at
                consectetur aperiam, facere esse distinctio ut placeat natus aspernatur!
                Et incidunt distinctio labore architecto aut voluptatum sint di
                perspiciatis. Impedit cum excepturi exercitationem delectus quasi.
            </p>
            <br>
            </br>
            <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Delectus perferendis magni illum, eveniet quis numquam at. Exercitationem, minus. Ullam, minima!
            </p>

        </div>
    );
};

export default SailerDetails;