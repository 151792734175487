import React from "react";
import "./AboutDetails.css";

const AboutDetails = () => {
  return (
    <div className="about-container">
      <h1>درباره ما</h1>
      <h2>آنچه که درباره ما ، لازمه بدونید</h2>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel at
        consectetur aperiam, facere esse distinctio ut placeat natus aspernatur!
        Et incidunt distinctio labore architecto aut voluptatum sint dicta?
        Mollitia veniam aperiam ipsum natus, repellat doloribus dolores facere
        praesentium, aut nam eos nihil cumque fugiat odio ducimus laudantium
        exercitationem magni! Odio veritatis aliquid et sequi. Voluptas quia
        neque dicta facilis in, nesciunt ducimus laboriosam delectus voluptates
        ea natus qui excepturi rerum ipsum, possimus debitis error! Sequi cum
        voluptatem dolorem id officiis, fugiat et laborum cumque optio nisi
        mollitia commodi adipisci consectetur similique beatae, aut
        perspiciatis. Impedit cum excepturi exercitationem delectus quasi.
      </p>
    </div>
  );
};

export default AboutDetails;
